import { VStack, Text, Button, Stack, Box, Img, Grid } from '@chakra-ui/react';
import commonAssets from 'assets/common';
import { goToForm } from 'shared/lib/goToForm';
import React, { memo } from 'react';
import AnimatedText from 'shared/AnimatedText';
import GreetingBullet from 'shared/GreetingBullet';
import { bullets } from 'constants/projects/bullets.js';

const GreetingsProjects = () => {
  return (
    <>
      <VStack spacing={{ mobile: '48px', tablet: '64px', laptop: '80px' }} align="start" w="100%">
        <Text
          as="h1"
          maxW={{ mobile: '343px', tablet: '696px', laptop: '1024px', desktop: '780px' }}
        >
          Разработки <br />и отраслевые решения
        </Text>
        <Button size="tertiary" onClick={goToForm}>
          Оставить заявку
        </Button>
      </VStack>
      <Stack
        direction={{ mobile: 'column', tablet: 'row' }}
        w="full"
        pos="relative"
        zIndex={800}
        gap={{ mobile: '28px', tablet: '40px', laptop: '20px' }}
      >
        <Box flex={1}>
          <Img
            h={{ mobile: '56px', tablet: 'fit-content' }}
            w={{ mobile: '44px', tablet: 'fit-content' }}
            src={commonAssets.icons.Aston}
            alt=""
          />
        </Box>

        <VStack spacing={{ mobile: '40px', tablet: '48px' }} flex={1} align="start">
          <AnimatedText as="h3" maxW={{ mobile: '500px', tablet: '720px' }}>
            С 2007 года разрабатываем ПО для клиентов b2b-сегмента и помогаем бизнесу выйти на новый
            уровень
          </AnimatedText>
          <Grid
            templateColumns="1fr 1fr"
            columnGap={{ mobile: '42px', tablet: '20px' }}
            rowGap={{ mobile: '32px', laptop: '40px' }}
            w="full"
            className="greetings__stats"
          >
            {bullets.map((data, i) => (
              <GreetingBullet key={i} {...data} />
            ))}
          </Grid>
          <Box mt={{ mobile: '0px', tablet: '8px', laptop: '16px' }} w="full">
            <Button
              size={{ laptop: 'tertiary', mobile: 'secondary' }}
              w={{ mobile: 'full', tablet: 'fit-content' }}
              onClick={goToForm}
            >
              Оставить заявку
            </Button>
          </Box>
        </VStack>
      </Stack>
    </>
  );
};

export default memo(GreetingsProjects);
