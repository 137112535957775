export const bullets = [
  {
    title: '400+',
    subTitle: 'проектов',
  },
  {
    title: '17+',
    subTitle: 'лет опыта',
  },
];
