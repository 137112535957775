import React, { memo } from 'react';
import { Button, VStack } from '@chakra-ui/react';

import Reviews from 'widgets/Reviews';
import ContactUs from 'widgets/ContactUs';
import PageMetaData from 'widgets/PageMetaData';
import GreetingsProjects from 'widgets/GreetingsProjects';
import ProjectCard from '~entities/ProjectCard';
import { ProjectsLayout } from 'shared/ProjectsLayout';
import { goToForm } from 'shared/lib/goToForm';
import Wrapper from 'shared/Wrapper';

import { primaryProjects } from 'constants/projects/primary';
import { secondaryProjects } from 'constants/projects/secondary';
import { primaryLayout, secondaryLayout } from 'constants/projects/projectsLayoutConfigs';

const ProjectsPage = () => (
  <>
    <PageMetaData
      title="Реализованные проекты и отзывы — ASTON"
      description="С 2007 года разработаны сотни проектов для среднего и крупного бизнеса."
      img={{
        src: 'https://storage.yandexcloud.net/dev.astonsite.s3backet/astondevs/metaPreviews/our-projects.png',
        alt: 'Превью для страницы Проекты',
      }}
    />
    <Wrapper>
      <GreetingsProjects />
      <VStack spacing="40px" w="full">
        <VStack w="full" spacing={{ mobile: '12px', tablet: '16px', laptop: '20px' }}>
          <ProjectsLayout
            projects={primaryProjects}
            config={primaryLayout}
            ElementComponent={ProjectCard}
          />
          <ProjectsLayout
            projects={secondaryProjects}
            config={secondaryLayout}
            alter={true}
            ElementComponent={ProjectCard}
          />
        </VStack>
        <Button w="full" size="secondary" onClick={goToForm}>
          Оставить заявку
        </Button>
      </VStack>
      <Reviews />
      <ContactUs />
    </Wrapper>
  </>
);

export default memo(ProjectsPage);
